import './bootstrap.js';
import { registerVueControllerComponents } from '@symfony/ux-vue';
import { createApp } from 'vue';
import './styles/app.css';
import Swiper from "swiper";
import { Autoplay, FreeMode } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { trans } from "./js/utilities/leakblock-translations";

const isMobile = window.innerWidth < 768; // Common mobile breakpoint

AOS.init({
    offset: isMobile ? 50 : 100,
    once: true,
    duration: 800,
    easing: 'ease-out',
    anchorPlacement: 'center-bottom',
    debounceDelay: 50,
    throttleDelay: 99
});

Swiper.use([Autoplay, FreeMode]);

const app = createApp({});

registerVueControllerComponents(require.context('./vue/controllers', true, /\.vue$/));

app.directive("click-outside", {
    beforeMount: function (el, binding) {
        // Define ourClickEventHandler
        const ourClickEventHandler = event => {
            if (!el.contains(event.target) && el !== event.target) {
                binding.value(event);
            }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;

        document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function (el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
    }
})

app.mount('#app');

const homeFourBanner = document.querySelector("#hero-banner");
const homeFourImag = document.querySelector("#hero-banner .img");
let perspectiveValue = 20; // Initial perspective value
if (homeFourBanner) {
    document.addEventListener("scroll", (e) => {
        const top = window.pageYOffset || document.documentElement.scrollTop;
        if (homeFourImag) {
            if (top > 500) {
                homeFourImag.style.transform = "none";
            } else {
                const calcValue = perspectiveValue + top / 2;
                homeFourImag.style.transform = `perspective(${calcValue}px) rotateX(1deg)`;
            }
        }
    });
}

window.addEventListener('load', () => {
    if (document.getElementsByClassName("h1-partner_slider")) {
        new Swiper(".h1-partner_slider", {
            slidesPerView: "auto",
            freeMode: true,
            spaceBetween: 50,
            loop: true,
            forceLoop: true,
            speed: 5000,
            centeredSlides: true,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
            },
            pagination: false,
            navigation: false,
            watchSlidesProgress: true,
            preventInteractionOnTransition: true,
        })
    }
});

const initFAQ = () => {
    const faqContainers = document.querySelectorAll('.faq-container');
    if (!faqContainers.length) return;

    // Pre-calculate all content heights to avoid layout shifts
    const contentHeights = new Map();
    document.querySelectorAll('.faq-content').forEach(content => {
        const inner = content.querySelector('div');
        contentHeights.set(content, inner.offsetHeight);
    });

    // Function to check if we're on mobile
    const isMobile = () => window.innerWidth < 1024;

    // Function to close all FAQ items
    const closeAllItems = () => {
        document.querySelectorAll('.faq-item').forEach(item => {
            const content = item.querySelector('.faq-content');
            const trigger = item.querySelector('.faq-trigger');
            const icon = trigger?.querySelector('svg');

            content.style.height = '0';
            content.style.opacity = '0';
            icon?.classList.remove('rotate-180');
            trigger?.classList.remove('bg-gray-100');
        });
    };

    // Function to open a specific FAQ item
    const openItem = (item) => {
        const content = item.querySelector('.faq-content');
        const trigger = item.querySelector('.faq-trigger');
        const icon = trigger?.querySelector('svg');

        content.style.height = `${contentHeights.get(content)}px`;
        content.style.opacity = '1';
        icon?.classList.add('rotate-180');
        trigger?.classList.add('bg-gray-100');
    };

    // Initial state setup
    const setupInitialState = () => {
        closeAllItems();

        if (isMobile()) {
            // On mobile, only open the first FAQ item overall
            const firstItem = document.querySelector('.faq-item');
            if (firstItem) openItem(firstItem);
        } else {
            // On desktop, open the first item of each container
            faqContainers.forEach(container => {
                const firstItem = container.querySelector('.faq-item');
                if (firstItem) openItem(firstItem);
            });
        }
    };

    // Set up click handlers
    faqContainers.forEach(container => {
        const triggers = container.querySelectorAll('.faq-trigger');

        triggers.forEach(trigger => {
            trigger.addEventListener('click', function() {
                const item = this.closest('.faq-item');
                const content = item.querySelector('.faq-content');
                const icon = this.querySelector('svg');

                // Close all other items, considering mobile/desktop state
                if (isMobile()) {
                    // On mobile, close ALL items across ALL containers
                    document.querySelectorAll('.faq-item').forEach(otherItem => {
                        if (otherItem !== item) {
                            const otherContent = otherItem.querySelector('.faq-content');
                            const otherTrigger = otherItem.querySelector('.faq-trigger');
                            const otherIcon = otherTrigger.querySelector('svg');

                            otherContent.style.height = '0';
                            otherContent.style.opacity = '0';
                            otherIcon.classList.remove('rotate-180');
                            otherTrigger.classList.remove('bg-gray-100');
                        }
                    });
                } else {
                    // On desktop, only close items in the same container
                    const container = this.closest('.faq-container');
                    container.querySelectorAll('.faq-item').forEach(otherItem => {
                        if (otherItem !== item) {
                            const otherContent = otherItem.querySelector('.faq-content');
                            const otherTrigger = otherItem.querySelector('.faq-trigger');
                            const otherIcon = otherTrigger.querySelector('svg');

                            otherContent.style.height = '0';
                            otherContent.style.opacity = '0';
                            otherIcon.classList.remove('rotate-180');
                            otherTrigger.classList.remove('bg-gray-100');
                        }
                    });
                }

                // Toggle current item
                const isOpen = content.style.height !== '0px';
                if (!isOpen) {
                    content.style.height = `${contentHeights.get(content)}px`;
                    content.style.opacity = '1';
                    icon.classList.add('rotate-180');
                    this.classList.add('bg-gray-100');
                } else {
                    content.style.height = '0';
                    content.style.opacity = '0';
                    icon.classList.remove('rotate-180');
                    this.classList.remove('bg-gray-100');
                }

                requestAnimationFrame(() => {
                    AOS.refresh();
                });
            });
        });
    });

    // Initial setup
    setupInitialState();

    // Handle resize events
    let resizeTimeout;
    window.addEventListener('resize', () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(setupInitialState, 250);
    });

    // Initial AOS refresh
    AOS.refresh();
};

// Initialize on DOM content loaded
document.addEventListener('DOMContentLoaded', initFAQ);

CookieConsent.run({
    guiOptions: {
        consentModal: {
            layout: "cloud inline",
            position: "bottom center",
            equalWeightButtons: true,
            flipButtons: true
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        functionality: {}
    },
    disablePageInteraction: true,
    language: {
        default: document.documentElement.lang || 'en',
        translations: {
            [document.documentElement.lang]: {
                consentModal: {
                    title: trans('leakblock.cookies.settings'),
                    description: trans('leakblock.cookies.description'),
                    acceptAllBtn: trans('leakblock.cookies.acceptAll'),
                    acceptNecessaryBtn: trans('leakblock.cookies.rejectAll'),
                    showPreferencesBtn: trans('leakblock.cookies.managePreferences'),
                },
                preferencesModal: {
                    title: trans('leakblock.cookies.settings'),
                    acceptAllBtn: trans('leakblock.cookies.acceptAll'),
                    acceptNecessaryBtn: trans('leakblock.cookies.rejectAll'),
                    savePreferencesBtn: trans('leakblock.cookies.savePreferences'),
                    closeIconLabel: trans('leakblock.cookies.close'),
                    sections: [
                        {
                            title: trans('leakblock.cookies.necessary.title'),
                            description: trans('leakblock.cookies.necessary.description'),
                            linkedCategory: 'necessary'
                        },
                        {
                            title: trans('leakblock.cookies.functionality.title'),
                            description: trans('leakblock.cookies.functionality.description'),
                            linkedCategory: 'functionality',
                            cookieTable: {
                                headers: {
                                    name: trans('leakblock.cookies.table.service'),
                                    description: trans('leakblock.cookies.table.description')
                                },
                                body: [
                                    {
                                        name: trans('leakblock.cookies.calendly.name'),
                                        description: trans('leakblock.cookies.calendly.description')
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        }
    }
});
